import { StyleSheet, Image, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "top",
        fontSize: 9,
    },
    title: {
        width: '100%',
        fontSize: 22,
        textAlign: 'center',
    },
    refNo: {
        width: '100%',
        textAlign: 'right',
        paddingRight: 10
    },
    logoView: {
        width: '100%',
        paddingBottom: 10
    },
    logo: {
        alignSelf: 'center',
        height: 60
    },
    remark: {
        width: '100%',
        padding: 5
    },
    telephone: {
        width: '40%',
    },
    email: {
        width: '60%',
        textAlign: 'right'
    },
    hr_1: {
        borderTopWidth: 1,
        borderTopColor: 'black',
        height: 5
    },
    hr_3: {
        borderBottomWidth: 3,
        borderBottomColor: 'black',
    }
});

export default ({ catalogue, settings }) => {
    const telephone = settings.find(s => s.key === 'Telephone')?.value;
    const email = settings.find(s => s.key === 'Email')?.value;

    return (
        <>
            <View style={styles.row} fixed>
                <Text style={styles.refNo}>Ref No: {catalogue.code}</Text>
            </View>

            <View style={styles.row} fixed render={({ pageNumber }) => (pageNumber !== 1 && (
                <Text style={styles.title}>Rare Finds</Text>
            ))}/>

            <View style={styles.row}>
                <View style={styles.logoView}>
                    <Image
                        style={styles.logo}
                        src="/logo.png"
                    />
                </View>
            </View>
            <View style={styles.row} fixed>
                <Text style={styles.telephone}>Telephone: {telephone}</Text>
                <Text style={styles.email}>Email: {email}</Text>
            </View>
            <View style={styles.hr_3}></View>
            <View style={styles.row}>
                <Text style={styles.remark}>{catalogue.remark}</Text>
            </View>
            <View style={styles.hr_1} fixed></View>
        </>
    );
}