class Auth {
    async login(username, password) {
      const data = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password})
      });
      return await data.json();
    }
  }
  
  module.exports = new Auth();