import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { View, StyleSheet, Page, Font, Text } from '@react-pdf/renderer';
import Book from '../../services/book';
import PdfDocumentViewer from '../PdfDocumentViewer';
import LoadingPage from '../LoadingPage';
import PdfInsertsBook from './PdfInsertsBook';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/roboto/Roboto-Regular.ttf' },
        { src: '/fonts/roboto/Roboto-Bold.ttf', fontWeight: 'bold' },
        { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic' },
    ],
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        backgroundColor: "#fff",
        fontSize: 11,
        
        lineHeight: 1.5,
        flexDirection: "column"
    },
    hr: {
        borderBottomWidth: 1,
        borderBottomColor: '#c0c0c0',
        borderBottomStyle: 'dashed',
    },
});

export default () => {
    const { bookIds } = useParams();
    const mounted = useRef(true);
    const [reload, setReload] = useState(true);
    const [books, setBooks] = useState([]);

    const [loading, setLoading] = useState(true);
    const [booksLoading, setBooksLoading] = useState(true);
    const [pdfLoading, setPdfLoading] = useState(true);

    useEffect(() => {
        setLoading(booksLoading || pdfLoading || !books || !books.length);
    }, [booksLoading, pdfLoading]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        mounted.current = true;

        Book.getByIds(bookIds)
            .then(items => {
                if (mounted.current) {
                    let selectedBooks = [...items];

                    // Sort by synopsis length
                    selectedBooks.sort((a, b) => (a.synopsis?.length || 0) - (b.synopsis?.length || 0));

                    selectedBooks = selectedBooks.map(b => { return { 
                        ...b,
                        Author: b.Author || { name: '' },
                        Subject: b.Subject || { name: '' },
                    }; })

                    setBooks(selectedBooks);
                    setBooksLoading(false);
                    setReload(false);
                }
            }
        );

        return () => mounted.current = false;
    }, [reload]);

    return (
        <LoadingPage loading={loading}>
            <PdfDocumentViewer title='Inserts' onLoad={() => setPdfLoading(false)}>
                <Page size="A4" style={styles.page}>
                    {books?.map((book, index) => (
                        <View key={index} break={book.synopsis && book.synopsis.length > 6500} wrap={book.synopsis && book.synopsis.length > 6500}>
                            <View><Text> </Text></View>
                            <PdfInsertsBook book={book} />
                            <View style={styles.hr}><Text> </Text></View>
                        </View>
                    ))}
                </Page>
            </PdfDocumentViewer>
        </LoadingPage>
    );
}