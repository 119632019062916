import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormText from '../../components/FormText';
import Auth from '../../services/auth';
import './index.css';
import FormButton from '../FormButton';

export default function PageLogin() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');

    const mounted = useRef(true);

    const onLoginSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!username || !password) {
            setStatus('Username and password cannot be blank');
            setLoading(false);
            return;
        }
        Auth.login(username, password)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        setStatus('Incorrect username or password');
                        return;
                    }
                    setStatus('');
                    localStorage.setItem('token', item.token);
                    window.location.href = "/books";
                }
            }).catch(err => {
                setStatus('Incorrect username or password');
            })
        setLoading(false);
    }
    return (
        <div className="Auth-form-container">
            <div className="Auth-form">
                <Form onSubmit={(e) => onLoginSubmit(e)}>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Sign In</h3>
                        <div className="form-group mt-3">
                            <FormText label='Username' value={username} onChange={(e) => setUsername(e.target.value)}></FormText>
                        </div>
                        <div className="form-group mt-3">
                            <FormText type="password" label='Password' value={password} onChange={(e) => setPassword(e.target.value)}></FormText>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <FormButton type="submit" onClick={(e) => onLoginSubmit(e)} loading={loading}>Login</FormButton>
                        </div>
                        <div className="form-group mt-3" style={{ color: 'red' }}>
                            {status}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}