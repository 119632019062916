import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export default function Footer() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary" fixed='bottom' data-bs-theme="dark">
            <Container>
                <div style={{color:'rgb(222, 226, 230)'}}>&copy; nybble Technologies</div>
            </Container>
        </Navbar>
    )
}