import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Party from '../../services/party';
import { Floppy, Trash, XLg } from 'react-bootstrap-icons';
import FormText from '../../components/FormText';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';

export default function PagePartyEdit() {
    const { id } = useParams();
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [party, setParty] = useState({});

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [partyLoading, setPartyLoading] = useState(true);

    useEffect(() => {
        setLoading(partyLoading)
    }, [partyLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const mounted = useRef(true);

    useEffect(() => { document.title = "Edit Party"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Party.getById(id)
            .then(item => {
                if (mounted.current) {
                    setParty(item);
                    setReload(false);
                    setPartyLoading(false);
                }
            })
            .catch(e => {
                window.location.href = "/notfound";
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (party) {
            setIsDirty(true);
            setParty({ ...party, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        Party.addUpdate(party)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setIsDirty(false);
                    setParty(item);
                    setAlert({ message: `Saved`, isError: false });
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            Party.delete(party).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = '/parties'
            });
        } else {
            // Do nothing
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>{party.namePrefix} {party.name}</Col>
                    {id !== '0' && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col lg={1}>
                        <FormText value={party.namePrefix || ''} label='Title' onChange={(e) => onFieldChanged('namePrefix', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.contactPerson || ''} label='Contact Person' onChange={(e) => onFieldChanged('contactPerson', e.target.value)} />
                    </Col>
                    <Col lg={1}>
                        <FormText value={party.group || ''} label='Party Group' onChange={(e) => onFieldChanged('group', e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg>
                        <FormText value={party.email || ''} label='Email' onChange={(e) => onFieldChanged('email', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.mobile || ''} label='Mobile' onChange={(e) => onFieldChanged('mobile', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.telephone || ''} label='Telephone' onChange={(e) => onFieldChanged('telephone', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.fax || ''} label='Fax' onChange={(e) => onFieldChanged('fax', e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg>
                        <FormText value={party.addressLine1 || ''} label='Address Line 1' onChange={(e) => onFieldChanged('addressLine1', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.addressLine2 || ''} label='Address Line 2' onChange={(e) => onFieldChanged('addressLine2', e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg>
                        <FormText value={party.addressLine3 || ''} label='Address Line 3' onChange={(e) => onFieldChanged('addressLine3', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={party.addressLine4 || ''} label='Address Line 4' onChange={(e) => onFieldChanged('addressLine4', e.target.value)} />
                    </Col>
                </Row>


                <FormText value={party.requirement || ''} label='Requirement' onChange={(e) => onFieldChanged('requirement', e.target.value)} as='textarea' style={{ height: '100px' }} />
                <FormText value={party.remark || ''} label='Remark' onChange={(e) => onFieldChanged('remark', e.target.value)} as='textarea' style={{ height: '100px' }} />
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(party, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}