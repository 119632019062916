import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        fontSize: 8,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: -15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    hr_1: {
        borderTopWidth: 1,
        borderTopColor: 'grey',
        height: 5
    },
    footRemark: {
        fontSize: 8,
        textAlign: 'justify'
    }
});

export default ({ catalogue }) => {
    return (
        <>
            <View style={styles.row}><Text> </Text></View>
            <View style={styles.row}>
                <Text style={styles.footRemark}>{catalogue.footRemark}</Text>
            </View>
            <View style={styles.pageNumber} fixed>
                <View style={styles.hr_1} fixed></View>
                <Text render={({ pageNumber, totalPages }) =>
                    `Page ${pageNumber} of ${totalPages}`
                } />
            </View>
        </>
    );
}