import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Book, BoxArrowRight, CardHeading, Gear, Journal, Pen, Person } from 'react-bootstrap-icons';
import './index.css';

export default function Header() {

    const location = useLocation();

    const logout = () => {
        localStorage.setItem('token', '');
        window.location.href = "/login";
    }
    return (
        <>
            {localStorage.getItem('token') && location.pathname !== '/' && location.pathname !== '/login' &&
                <Navbar expand="sm" fixed='top' data-bs-theme="dark" style={{ paddingLeft: '2rem', paddingRight: '2rem', backgroundColor: '#1e4472', fontWeight: 'bold' }}>
                    <Navbar.Brand href="/books"> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" activeKey={location.pathname}>
                            <Nav.Link href="/books"><Book size={20} /> Books</Nav.Link>
                            <Nav.Link href="/authors"><Pen size={20} /> Authors</Nav.Link>
                            <Nav.Link href="/subjects"><CardHeading size={20} /> Subjects</Nav.Link>
                            <Nav.Link href="/parties"><Person size={20} /> Parties</Nav.Link>
                            <Nav.Link href="/catalogues"><Journal size={20} /> Catalogues</Nav.Link>
                        </Nav>

                        <Nav activeKey={location.pathname}>
                            <Nav.Link href="/settings"><Gear size={20} /> Settings</Nav.Link>
                            <Nav.Link onClick={logout}><BoxArrowRight size={20} /> Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            }
        </>
    )
}