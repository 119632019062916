import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Catalogue from '../../services/catalogue';
import { Asterisk, Copy, FilePdf, FileText, Floppy, Trash, XLg } from 'react-bootstrap-icons';
import FormText from '../../components/FormText';
import SearchBar from '../SearchBar';
import Content from '../Content';
import CellLinkRenderer from '../CellLinkRenderer';
import CellDelete from '../CellDelete';
import AlertBox from '../AlertBox';
import CellHeaderAdd from '../CellHeaderAdd';
import CellHeaderCataloguePrice from '../CellHeaderCataloguePrice';
import LoadingPage from '../LoadingPage';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './index.css';
import Utils from '../../utils';

export default () => {
    const { id } = useParams();
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [catalogue, setCatalogue] = useState({});
    const [originalCode, setOriginalCode] = useState(id);
    const [onMultiplyCataloguePrice, setOnMultiplyCataloguePrice] = useState(false);
    const [printingOptions, setPrintingOptions] = useState(false);
    const [priceMultiplier, setPriceMultiplier] = useState(1);
    const [duplicateCatelogueId, setDuplicateCatelogueId] = useState(null);

    const [printableFields, setPrintableFields] = useState(
        [
            { fields: ['synopsis'], value: true, label: 'Synopsis' },
            { fields: ['price', 'currency'], value: true, label: 'Price' },
            { fields: ['remark'], value: true, label: 'Remark' },
            { fields: ['footRemark'], value: true, label: 'Foot Remark' },
            { fields: ['numberOfPages'], value: true, label: 'Number Of Pages' },
            { fields: ['yearOfPublication'], value: true, label: 'Year Of Publication' },
            { fields: ['placeOfPrinting'], value: true, label: 'Place Of Printing' },
            { fields: ['coverDetails'], value: true, label: 'Cover Details' },
            { fields: ['volumeDetails'], value: true, label: 'Volume Details' },
            { fields: ['bookSize'], value: true, label: 'Book Size' },
        ]
    );
    const [fieldsToHide, setFieldsToHide] = useState();

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [catalogueLoading, setCatalogueLoading] = useState(true);

    useEffect(() => {
        setLoading(catalogueLoading)
    }, [catalogueLoading]);

    useEffect(() => {
        if (!alert && duplicateCatelogueId !== null) {
            window.location.href = `/catalogues/${duplicateCatelogueId}`;
        }

        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Edit Catalogue"; }, []);

    useEffect(() => {
        const f = [...printableFields.filter(f => !f.value).map(f => f.fields)];
        setFieldsToHide(f.join(','));
    }, [printableFields]);

    useEffect(() => {
        if (!printingOptions) {
            return;
        }
        if (!originalCode || catalogue.code === '' || isDirty) {
            setAlert({ message: 'Save catalogue before generating pdf', isError: true });
            setPrintingOptions(false);
        }
    }, [printingOptions])

    const [onBookAdd, setOnBookAdd] = useState(false);
    useEffect(() => {
        if (submitting) {
            setOnBookAdd(false);
            return;
        }
        if (onBookAdd && originalCode && originalCode !== '0' && originalCode !== '') {
            window.location.href = `/books?catalogue=${originalCode}`;

        } else if (onBookAdd) {
            setAlert({ message: 'Save catalogue to add a book', isError: true });
        }

        setOnBookAdd(false);
    }, [onBookAdd]);

    const [onResetCataloguePrice, setOnResetCataloguePrice] = useState(false);
    useEffect(() => {
        if (submitting) {
            setOnResetCataloguePrice(false);
            return;
        }
        if (onResetCataloguePrice) {
            const catalogueBooks = [...catalogue.Books];
            catalogueBooks.forEach(b => {
                b.CatalogueBook.price = Number(b.priceInCurrency);
            });
            setCatalogue({ ...catalogue, Books: catalogueBooks });
            setIsDirty(true);
        }
        setOnResetCataloguePrice(false);
    }, [onResetCataloguePrice]);

    const [removeBookId, setRemoveBookId] = useState('')
    useEffect(() => {
        if (submitting) {
            setRemoveBookId('');
            return;
        }

        if (removeBookId === '') {
            return;
        }

        const trimCatalogue = { ...catalogue };
        trimCatalogue.Books = [...catalogue.Books];

        const index = trimCatalogue.Books.findIndex(obj => obj.id === removeBookId);
        if (index !== -1) {
            trimCatalogue.Books.splice(index, 1);
        }

        setCatalogue(trimCatalogue);
        setIsDirty(true);
        setRemoveBookId('');
    }, [removeBookId])

    const [colBookDefs, setColBookDefs] = useState([
        {
            field: 'name',
            headerName: 'Book Name',
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/books/${params.data.id}?catalogue=${originalCode}`, title: params.data.name } }
        },
        {
            field: 'price', type: 'rightAligned', maxWidth: 200, editable: true, field: "number",
            valueFormatter: params => params.data.CatalogueBook.price?.toFixed(2),
            valueSetter: params => {
                params.data.CatalogueBook.price = Number(params.newValue);
                return true;
            },
            headerComponent: CellHeaderCataloguePrice,
            headerComponentParams: {
                onReset: () => setOnResetCataloguePrice(true),
                onMultiply: () => setOnMultiplyCataloguePrice(true),
            }
        },
        {
            field: 'delete',
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellDelete,
            cellRendererParams: (params) => { return { onClick: () => setRemoveBookId(params.data.id) } },
            sortable: false,
            headerComponent: CellHeaderAdd,
            headerComponentParams: {
                onClick: () => setOnBookAdd(true)
            }
        },
        { field: 'sold', hide: true },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            resizable: false,
            lockPosition: 'right',
            comparator: Utils.defaultComparator,
        };
    }, []);
    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Catalogue.getById(originalCode)
            .then(item => {
                if (mounted.current) {
                    if (item === null || Object.keys(item).length === 0) {
                        item.date = moment(new Date);
                        item.Books = []
                    }
                    setCatalogue(item);
                    setOriginalCode(item.id)
                    setReload(false);
                    setCatalogueLoading(false);
                }
            })
            .catch(e => {
                // window.location.href = "/notfound";
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (catalogue) {
            setIsDirty(true);
            setCatalogue({ ...catalogue, [field]: value });
        }
    }
    
    const onDuplicate = () => {
        if (!originalCode || catalogue.code === '' || isDirty) {
            setAlert({ message: 'Save catalogue before duplicating', isError: true });
            setDuplicateCatelogueId(null);
        }
        
        setSubmitting(true);
        Catalogue.duplicate(catalogue)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setDuplicateCatelogueId(item.id);
                    setAlert({ message: `Duplicated`, isError: false });                    
                }
            });
    }

    const onSave = async () => {
        setSubmitting(true);
        let catalogueCodeExists = false;

        if (!catalogue.code) {
            setAlert({ message: `Catagory Code cannot be empty`, isError: true });
            return;
        }

        if (originalCode !== catalogue.id) {
            // check if new id already exists
            try {
                const item = await Catalogue.getById(catalogue.id);
                if (item) {
                    catalogueCodeExists = true;
                    setAlert({ message: `Catagory Code already exists`, isError: true });
                }
            } catch (error) {
                catalogueCodeExists = false;
            }
        }

        if (!catalogueCodeExists) {
            const trimCatalogue = { ...catalogue };
            trimCatalogue.Books = [...catalogue.Books].map(b => { return { id: b.id, price: b.CatalogueBook.price } });

            Catalogue.addUpdate(trimCatalogue)
                .then(item => {
                    if (mounted.current) {
                        if (item.error) {
                            return setAlert({ message: item.error, isError: true });
                        }

                        setIsDirty(false);
                        setAlert({ message: `Saved`, isError: false });
                        setOriginalCode(item.id);
                        setReload(true);
                    }
                });
        }
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            Catalogue.delete(catalogue).then(b => {
                window.location.href = '/catalogues'
            });
        } else {
            // Do nothing
        }
    }

    const onCellValueChanged = (params) => {
        //var changedData = [params.data];
        setIsDirty(true);
        //params.api.applyTransaction({ update: changedData });
    }

    const multiplyCataloguePrice = () => {
        setOnMultiplyCataloguePrice(false);
        const catalogueBooks = [...catalogue.Books];
        catalogueBooks.forEach(b => {
            b.CatalogueBook.price = Number(b.priceInCurrency) * Number(priceMultiplier);
        });
        setCatalogue({ ...catalogue, Books: catalogueBooks });
        setIsDirty(true);
    }

    const onPdfCreate = () => {
        window.open(`/catalogues/preview/${catalogue.id}?hide=${fieldsToHide}`, '_target');
    }

    const onInsertsCreate = () => {
        window.open(`/inserts/preview/${catalogue.Books.map(b => b.id).join(',')}`, '_target');
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto" style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>{catalogue.name ? catalogue.name : catalogue.code}</Col>
                    <Col sm><Badge bg="danger" style={{ fontSize: '1rem' }}>{catalogue.Books ? catalogue.Books.length : 0} Books</Badge></Col>
                    {id !== '0' && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onDuplicate()} title='Duplicate' disabled={submitting}><Copy size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-primary" onClick={() => setPrintingOptions(true)} title='Create pdf' disabled={submitting}><FilePdf size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-primary" onClick={() => onInsertsCreate()} title='Generate Inserts' disabled={submitting}><FileText size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col lg>
                        <FormText value={catalogue.code || ''} label='Catlogue Code' onChange={(e) => onFieldChanged('code', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={catalogue.name || ''} label='Catlogue Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText type='date' value={(catalogue.date && moment(catalogue.date).format('YYYY-MM-DD')) || ''} label='Catlogue Date' onChange={(e) => onFieldChanged('date', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={catalogue.currency || ''} label='Catlogue Currency' onChange={(e) => onFieldChanged('currency', e.target.value)} />
                    </Col>
                </Row>
                <FormText value={catalogue.remark || ''} label='Remark' onChange={(e) => onFieldChanged('remark', e.target.value)} as='textarea' style={{ height: '100px' }} />
                <FormText value={catalogue.footRemark || ''} label='Foot remark' onChange={(e) => onFieldChanged('footRemark', e.target.value)} as='textarea' style={{ height: '100px' }} />

                <div className="ag-theme-quartz p-0" style={{ height: '20rem' }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        columnDefs={colBookDefs}
                        // domLayout="autoHeight"
                        onCellValueChanged={onCellValueChanged}
                        suppressScrollOnNewData='true'
                        rowData={catalogue.Books}
                        rowClassRules= {
                            {
                                "row-sold": params => params.api.getValue("sold", params.node),
                                "row-unsold": params => !params.api.getValue("sold", params.node)
                            }
                          }
                    />
                </div>
            </Content>

            <Modal show={onMultiplyCataloguePrice && !submitting} onHide={() => setOnMultiplyCataloguePrice(false)} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor: '#3498db', color: 'white' }}>
                    <Modal.Title>Catalogue Price Multiplier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control placeholder="Multiplier" aria-label="Multiplier" value={priceMultiplier} onChange={(e) => setPriceMultiplier(e.target.value)} />
                    <Row className='justify-content-md-center'>
                        <Col md="auto">
                            <Form.Text className="text-danger">{alert && alert.message}</Form.Text>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setOnMultiplyCataloguePrice(false)} title='Close' disabled={submitting}><XLg size={30} /></Button>
                    <Button variant="outline-success" onClick={() => multiplyCataloguePrice()} title='Multiply' disabled={submitting}><Asterisk size={30} /></Button>
                </Modal.Footer>
            </Modal>

            <Modal show={printingOptions && !submitting} onHide={() => setPrintingOptions(false)} backdrop="static" keyboard={false}>
                <Modal.Header style={{ backgroundColor: '#3498db', color: 'white' }}>
                    <Modal.Title>Create Catalogue pdf</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                            {printableFields.map((o, index) =>
                                <Form.Check key={index} type="switch" label={o.label || o.fields[0]} checked={o.value}  onChange={() => {
                                    const newPrintableFields = [...printableFields];
                                    newPrintableFields[index].value = !newPrintableFields[index].value;
                                    return setPrintableFields(newPrintableFields);
                                }}/>
                            )}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setPrintingOptions(false)} title='Close' disabled={submitting}><XLg size={30} /></Button>
                    <Button variant="outline-success" onClick={() => onPdfCreate()} title='Create pdf' disabled={submitting}><FilePdf size={30} /></Button>
                </Modal.Footer>
            </Modal>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(catalogue, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}