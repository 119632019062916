import React from "react";
import { ArrowClockwise, Asterisk } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

export default (params) => {
  return (
    <div>
      Catlogue Price <Button title='Reset Price' variant="danger" size="sm" onClick={params.onReset}><ArrowClockwise /></Button> <Button title='Price Multiplier' variant="primary" size="sm" onClick={params.onMultiply}><Asterisk /> </Button>
    </div>
  )
}