import { Route, Routes } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import PageAuthors from '../PageAuthors';
import PageSubjects from '../PageSubjects';
import PageBooks from '../PageBooks';
import PageParties from '../PageParties';
import PageCatalogues from '../PageCatalogues';
import PageBookEdit from '../PageBookEdit';
import PagePartyEdit from '../PagePartyEdit';
import PageCatalogueEdit from '../PageCatalogueEdit';
import PageError from '../PageError';
import PageNotFound from '../PageNotFound';
import PageLogin from '../PageLogin';
import AuthGuard from '../AuthGuard';
import PdfCatalogue from '../PdfCatalogue';
import PageSettings from '../PageSettings';
import PdfInserts from '../PdfInserts';
import './index.css';

function App() {
  return (
    <div className="App">
        <Header></Header>
          <Routes>
            {/* <Route exact path="/" element={<Home />} /> */}
            <Route exact path="/" element={<PageLogin />} />
            <Route exact path="/login" element={<PageLogin />} />
            <Route exact path="/authors" element={<AuthGuard><PageAuthors /></AuthGuard>} />
            <Route exact path="/subjects" element={<AuthGuard><PageSubjects /></AuthGuard>} />
            <Route exact path="/books" element={<AuthGuard><PageBooks /></AuthGuard>} />
            <Route exact path="/books/:id" element={<AuthGuard><PageBookEdit /></AuthGuard>} />
            <Route exact path="/parties" element={<AuthGuard><PageParties /></AuthGuard>} />
            <Route exact path="/parties/:id" element={<AuthGuard><PagePartyEdit /></AuthGuard>} />
            <Route exact path="/catalogues" element={<AuthGuard><PageCatalogues /></AuthGuard>} />
            <Route exact path="/catalogues/:id" element={<AuthGuard><PageCatalogueEdit /></AuthGuard>} />
            <Route exact path="/catalogues/preview/:id" element={<AuthGuard><PdfCatalogue /></AuthGuard>} />
            <Route exact path="/inserts/preview/:bookIds" element={<AuthGuard><PdfInserts /></AuthGuard>} />
            <Route exact path="/settings" element={<AuthGuard><PageSettings /></AuthGuard>} />
            <Route path="/error" element={<PageError />} />
            <Route path="/notfound" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      <br />
      <br />
      <footer id='footer'>
        <Footer></Footer>
      </footer>
    </div>
  );
}

export default App;
