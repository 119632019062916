class Party {
  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/parties?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/parties/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(party) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/parties/${party.id || ''}`, {
      method: party.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(party)
    });
    return await data.json();
  }

  async delete(party) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/parties/${party.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Party();