import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { View, StyleSheet, Page, Font } from '@react-pdf/renderer';
import Catalogue from '../../services/catalogue';
import Setting from '../../services/setting';
import PdfDocumentViewer from '../PdfDocumentViewer';
import PdfCatalogueHeader from './PdfCatalogueHeader';
import PdfCatalogueBooks from './PdfCatalogueBooks';
import PdfCatalogueFooter from './PdfCatalogueFooter';
import LoadingPage from '../LoadingPage';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/roboto/Roboto-Regular.ttf' },
        { src: '/fonts/roboto/Roboto-Bold.ttf', fontWeight: 'bold' },
        { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic' },
    ],
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        backgroundColor: "#fff",
        fontSize: 11,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: "column"
    },
    section: {
        flexGrow: 1,
    },
});

export default () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const fieldsToHide = searchParams.get('hide')?.split(',');
    const mounted = useRef(true);
    const [reload, setReload] = useState(true);
    const [catalogue, setCatalogue] = useState({});
    const [settings, setSettings] = useState([]);

    const [loading, setLoading] = useState(true);
    const [catalogueLoading, setCatalogueLoading] = useState(true);
    const [settingsLoading, setSettingsLoading] = useState(true);
    const [pdfLoading, setPdfLoading] = useState(true);

    useEffect(() => {
        setLoading(catalogueLoading || settingsLoading || pdfLoading || !catalogue || !catalogue.code || !settings || !catalogue.Books);
    }, [catalogueLoading, settingsLoading, pdfLoading]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        mounted.current = true;

        Catalogue.getById(id)
            .then(c => {
                if (mounted.current) {
                    let catalogue = { ...c };
                    let books = [...c.Books];

                    books = books.map(b => { return { 
                        ...b,
                        CatalogueBook: { ...b.CatalogueBook, price: Number(b.CatalogueBook.price).toFixed(2) },
                        Author: b.Author || { name: '' },
                        Subject: b.Subject || { name: '' },
                    }; })

                    fieldsToHide.forEach(field => {
                        catalogue[field] = '';
                        books = books.map(b => { return { ...b, [field]: '', CatalogueBook: { ...b.CatalogueBook, [field]: ''} } });
                    });

                    catalogue.Books = books;

                    setCatalogue(catalogue);
                    setCatalogueLoading(false);
                    Setting.get()
                        .then(s => {
                            setSettings(s);
                            setSettingsLoading(false);
                            setReload(false);
                        });
                }
            });

        return () => mounted.current = false;
    }, [reload]);

    return (
        <LoadingPage loading={loading}>
            <PdfDocumentViewer title={`${catalogue.code}`} onLoad={() => setPdfLoading(false)}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <PdfCatalogueHeader catalogue={catalogue} settings={settings} />
                        <PdfCatalogueBooks catalogue={catalogue} />
                        <PdfCatalogueFooter catalogue={catalogue} />
                    </View>
                </Page>
            </PdfDocumentViewer>
        </LoadingPage>
    );
}