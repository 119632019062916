class Subject {
  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/subjects?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(subject) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/subjects/${subject.id || ''}`, {
      method: subject.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(subject)
    });
    return await data.json();
  }

  async delete(subject) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/subjects/${subject.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Subject();