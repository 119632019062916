import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Book from '../../services/book';
import Author from '../../services/author';
import Subject from '../../services/subject';
import Party from '../../services/party';
import Catalogue from '../../services/catalogue';
import { Floppy, Trash, XLg, BoxArrowInDownRight, PlusLg } from 'react-bootstrap-icons';
import FormText from '../../components/FormText';
import FormSelect from '../../components/FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import './index.css';
import LoadingPage from '../LoadingPage';
import moment from 'moment';

export default () => {
    const mounted = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [books, setBooks] = useState([]);
    const [book, setBook] = useState({});
    const [authors, setAuthors] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [parties, setParties] = useState([]);
    const [newAuthor, setNewAuthor] = useState({});
    const [newSubject, setNewSubject] = useState({});
    const [showAddAuthor, setShowAddAuthor] = useState(false);
    const [showAddSubject, setShowAddSubject] = useState(false);
    const [showSoldParty, setShowSoldParty] = useState(true);
    const [selectedCopy, setSelectedCopy] = useState(id);
    const [catalogueId, setCatalogueId] = useState(searchParams.get('catalogue') || '');
    const [catalogue, setCatalogue] = useState({});

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [booksLoading, setBooksLoading] = useState(true);
    const [bookLoading, setBookLoading] = useState(true);
    const [authorsLoading, setAuthorsLoading] = useState(true);
    const [subjectsLoading, setSubjectsLoading] = useState(true);
    const [partiesLoading, setPartiesLoading] = useState(true);
    const [catalogueLoading, setCatalogueLoading] = useState(catalogueId ? true : false);

    useEffect(() => {
        setLoading(booksLoading || bookLoading || authorsLoading || subjectsLoading || partiesLoading || catalogueLoading)
    }, [booksLoading, bookLoading, authorsLoading, subjectsLoading, partiesLoading, catalogueLoading]);

    useEffect(() => { document.title = "Edit Book"; }, []);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => {
        if (catalogueId) {
            Catalogue.getById(catalogueId).then(c => {
                setCatalogue(c);
                setCatalogueLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Book.getById(id)
            .then(items => {
                if (mounted.current) {
                    // items = items.map(i => {return { ...i, sellingDate: moment(i.sellingDate).format('YYYY-MM-DD')}});
                    setBooks(items);
                    setBook(items.find(i => i.id.toString().toLowerCase() === selectedCopy) || items[0] || {});
                }
            })
            .catch(e => {
                
                //window.location.href = "/notfound";
            });

            setReload(false);
            setBooksLoading(false);
            setBookLoading(false);
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        mounted.current = true;
        if (books.length !== 0) {
            setBook(books.find(i => i.id && i.id.toString().toLowerCase() === selectedCopy) || books[0]);
        }
        return () => mounted.current = false;
    }, [selectedCopy]);

    useEffect(() => {
        mounted.current = true;
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            mounted.current = false;
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    useEffect(() => {
        mounted.current = true;
        if (showAddAuthor || showAddSubject) {
            setAlert('');
            return;
        }

        Author.get('').then(items => { setAuthors([{ id: 0, name: '---blank---' }, { id: -1, name: '---New---' }, ...items]); setAuthorsLoading(false); });
        Subject.get('').then(items => { setSubjects([{ id: 0, name: '---blank---' }, { id: -1, name: '---New---' }, ...items]); setSubjectsLoading(false); });
        Party.get('').then(items => { setParties([{ id: 0, name: '---blank---' }, ...items]); setPartiesLoading(false); });
        return () => mounted.current = false;
    }, [showAddAuthor, showAddSubject]);

    const onFieldChanged = (field, value) => {
        if (field === 'authorId' && value === '-1') {
            setShowAddAuthor(true);
            return;
        }
        if (field === 'subjectId' && value === '-1') {
            setShowAddSubject(true);
            return;
        }
        if (field === 'partyId' && value === '0') {
            value = null;
        }
        if (book) {
            setIsDirty(true);
            setBook({ ...book, [field]: value });
        }
    }

    const onSave = async () => {
        setSubmitting(true);
        let error = '';
        let items = [];

        // const index = books.findIndex(b => b.id.toString().toLowerCase() === selectedCopy);
        // let newList = [...books];
        // newList[index] = book;
        // setBooks(newList);
        // setSelectedCopy(selectedCopy);

        for (let i = 0; i < books.length; i++) {
            // if (books[i].id === '' && books[i].name === '') {
            //     continue;
            // }

            try {
                items = await Book.addUpdate(books[i]);
            } catch (err) {
                
            }
            
            if (items.error) {
                error = items.error;
                return;
            }
        }

        try {
            items = await Book.addUpdate(book);
        } catch (error) {
            
        }
           
        if (items.error) {
            error = items.error;
        }

        if (error === '') {
            setIsDirty(false);
            if (selectedCopy === '0') {
                setSelectedCopy(items[0].id);
                setBook(items[0]);
            }
            setBooks(items);
            setAlert({ message: `Saved`, isError: false });
            return;
        }

        setAlert({ message: error, isError: true });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            Book.delete(book).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                let newList = [...books].filter(b => b.id.toString().toLowerCase() !== selectedCopy);
                if(newList.length === 0) {
                    window.location.href = '/books'
                } else {
                    window.location.href = `/books/${newList[0].id}`;
                }
            }).catch(err => {
                setAlert({ message: 'Unable to delete', isError: true });
            });
        } else {
            // Do nothing
        }
    }

    const addUpdateAuthor = (author) => {
        setSubmitting(true);
        Author.addUpdate(author)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setBook({ ...book, authorId: item.id });
                    setAlert({ message: `Added ${item.name}`, isError: false });
                    setNewAuthor({});
                    setShowAddAuthor(false);
                }
            })
    }

    const addUpdateSubject = (subject) => {
        setSubmitting(true);
        Subject.addUpdate(subject)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setBook({ ...book, subjectId: item.id });
                    setAlert({ message: `Added ${item.name}`, isError: false });
                    setNewSubject({});
                    setShowAddSubject(false);
                }
            });
    }

    const changeCopy = (key) => {
        const index = books.findIndex(b => b.id.toString().toLowerCase() === selectedCopy);
        let newList = [...books];
        newList[index] = book;
        setBooks(newList);
        setSelectedCopy(key);
    }

    const onCopy = () => {
        setSubmitting(true);
        Book.addUpdate({...book, id: null, code: null, partyId: null, sellingDate: null, sold: false })
            .then(items => {
                if (mounted.current) {
                    if (items.error) {
                        return setAlert({ message: items.error, isError: true });
                    }

                    setBooks(items);
                    setAlert({ message: `Book Copied`, isError: false });
                }
            });
    }

    const addToCatalogue = () => {
        if (isDirty) {
            window.location.href = `/catalogues/${catalogueId}`;
            return;
        }
        setSubmitting(true);
        const catalogueBooks = [ ...catalogue.Books ];
        
        if (catalogueBooks.findIndex(cb => cb.id === book.id) === -1) {
            catalogueBooks.push({ ...book,  CatalogueBook: { price: book.priceInCurrency }  });
        }

        const trimCatalogue = { ...catalogue };
        trimCatalogue.Books = [...catalogueBooks].map(b => { return { id: b.id, price: b.CatalogueBook.price } });

        Catalogue.addUpdate(trimCatalogue).then(() => {
            window.location.href = `/catalogues/${catalogueId}`;
        }).catch(error => {
            setAlert({ message: `Unable to add to catalogue`, isError: true });
        });
    }

    const onSellClick = () => {
        setIsDirty(true);
        setBook({...book, sold: true, sellingDate: moment(new Date()).format("YYYY-MM-DD"), partyId: null});
        setShowSoldParty(true);
    }
    const onUnSellClick = () => {
        if (window.confirm("Are you sure you want to un-sell the book?") === true) {
            setIsDirty(true);
            setBook({...book, sold: false, sellingDate: moment(new Date()).format("YYYY-MM-DD"), partyId: null});
        } else {
            // Do nothing
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm>
                    <Nav variant="pills" defaultActiveKey={0} activeKey={selectedCopy} onSelect={(k) => changeCopy(k)} className='PageBookEdit'>
                    <Nav.Item><Nav.Link title='Copies' disabled>Copies</Nav.Link></Nav.Item>
                            {books && books.length > 0 && books.map((b, i) =>
                                <Nav.Item key={i}>
                                    <Nav.Link eventKey={b.id || 0} title={`${i+1}`} disabled={submitting}>{`${i+1}`}</Nav.Link>
                                </Nav.Item>
                            )}

                            <Nav.Item>
                                <Nav.Link title='Copy' onClick={onCopy} disabled={submitting}><PlusLg /></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    {id !== '0' && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                    {catalogueId && <Col sm="auto"><Button variant="outline-primary" onClick={() => addToCatalogue()} title='Add to catalogue' disabled={submitting}><BoxArrowInDownRight size={30} /></Button></Col>}
                </Row>
                
            </SearchBar>
            <Content>
                <Row>
                    <Col lg>
                        <FormText value={book.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                    </Col>
                    <Col lg={1}>
                        {book.sold
                            ? <Button className='mt-1' variant='danger' size='lg' style={{ width: '100%' }} onClick={() => setShowSoldParty(true)} title='Sold'>SOLD</Button>
                            : <Button className='mt-1' size='lg' style={{ width: '100%' }} onClick={onSellClick} title='Sell'>SELL</Button>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col lg>
                        <FormSelect value={book.authorId || ''} indexfield='id' label='Author' field='name' options={authors} onChange={(e) => onFieldChanged('authorId', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormSelect value={book.subjectId || ''} indexfield='id' label='Subject' field='name' options={subjects} onChange={(e) => onFieldChanged('subjectId', e.target.value)} />
                    </Col>
                    <Col lg={2}>
                        <FormText value={(book.language || '').toUpperCase()} label='Language Code' onChange={(e) => onFieldChanged('language', e.target.value)} />
                    </Col>
                </Row>

                <Row>
                    <Col lg>
                        <FormText value={book.yearOfPublication || ''} label='Year of Publication' onChange={(e) => onFieldChanged('yearOfPublication', e.target.value)} />
                    </Col>
                    {/* <Col lg>
                        <FormText value={book['Number of Books'] || 1} label='Number of Books' onChange={(e) => onFieldChanged('Number of Books', e.target.value)} type='number' step="1" />
                    </Col> */}
                    <Col lg>
                        <FormText value={book.numberOfPages || ''} label='Number of Pages' onChange={(e) => onFieldChanged('numberOfPages', e.target.value)} type='number' step="1" />
                    </Col>
                    <Col lg>
                        <FormText value={book.bookSize || ''} label='BookSize' onChange={(e) => onFieldChanged('bookSize', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={book.placeOfPrinting || ''} label='Place of Printing' onChange={(e) => onFieldChanged('placeOfPrinting', e.target.value)} />
                    </Col>
                    <Col lg={2}>
                        <FormText value={book.tableCodeU || ''} label='Table Code U' onChange={(e) => onFieldChanged('tableCodeU', e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg>
                        <FormText value={book.volumeDetails || ''} label='Volume Details' onChange={(e) => onFieldChanged('volumeDetails', e.target.value)} />
                    </Col>
                    <Col lg>
                        <FormText value={book.coverDetails || ''} label='Cover Details' onChange={(e) => onFieldChanged('coverDetails', e.target.value)} />
                    </Col>
                    <Col lg={2}>
                        <FormText value={book.tableCodeD || ''} label='Table Code D' onChange={(e) => onFieldChanged('tableCodeD', e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg>
                        <FormText value={book.ref || ''} label='Ref' onChange={(e) => onFieldChanged('ref', e.target.value)} />
                    </Col>
                    <Col lg={1}>
                        <FormText value={book.currency || ''} label='Currency' onChange={(e) => onFieldChanged('currency', e.target.value)} />
                    </Col>
                    <Col lg={2}>
                        <FormText value={book.priceInCurrency || ''} label='Price' onChange={(e) => onFieldChanged('priceInCurrency', e.target.value)} type='number'/>
                    </Col>
                </Row>
                <FormText value={book.remark || ''} label='Remark' onChange={(e) => onFieldChanged('remark', e.target.value)} as='textarea' style={{ height: '100px' }} />
                <FormText value={book.synopsis || ''} label='Synopsis' onChange={(e) => onFieldChanged('synopsis', e.target.value)} as='textarea' style={{ height: '250px' }} />
            </Content>

            {book.sold && showSoldParty &&
                <div>
                    <Content opacity='0.5'>

                    </Content>
                    
                    <div style={{ position: 'fixed', top: '15rem', right: '0', left: '0', textAlign: 'center', color: 'red', fontSize: '10rem', fontStyle: 'bold' }}>
                        <div>SOLD</div>
                    </div>
                    <div style={{ position: 'fixed', right: '0', top: '7.2rem', padding: '2rem', backgroundColor: 'rgb(34, 38, 75)', textAlign: 'center' }}>
                        <FormSelect value={book.partyId || ''} indexfield='id' label='Party Name' field='name' options={parties} onChange={(e) => onFieldChanged('partyId', e.target.value)} />
                        <FormText type='date' value={moment(book.sellingDate).format('YYYY-MM-DD')  || ''} label='Selling Date' onChange={(e) => onFieldChanged('sellingDate', e.target.value)} />
                        <Button variant='danger' onClick={onUnSellClick}>Cancel</Button> &nbsp;
                        <Button variant='primary' onClick={() => setShowSoldParty(false)}>Hide</Button>
                    </div>
                </div>
            }

            <Modal show={showAddAuthor} onHide={() => setShowAddAuthor(false)} backdrop="static" keyboard={false}>
                <Modal.Header style={{backgroundColor:'#3498db', color:'white'}}>
                    <Modal.Title>Author</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control placeholder="New author name" aria-label="New author name" value={newAuthor.name} onChange={(e) => setNewAuthor({ name: e.target.value })} />
                    <Row className='justify-content-md-center'>
                        <Col md="auto">
                            <Form.Text className="text-danger">{alert && alert.message}</Form.Text>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowAddAuthor(false)} title='Close' disabled={submitting}><XLg size={30} /></Button>
                    <Button variant="outline-success" onClick={() => addUpdateAuthor(newAuthor)} title='Save' disabled={submitting}><Floppy size={30} /></Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddSubject} onHide={() => setShowAddSubject(false)} backdrop="static" keyboard={false}>
                <Modal.Header style={{backgroundColor:'#3498db', color:'white'}}>
                    <Modal.Title>Subject</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control placeholder="New subject name" aria-label="New subject name" value={newSubject.name} onChange={(e) => setNewSubject({ name: e.target.value })} />
                    <Row className='justify-content-md-center'>
                        <Col md="auto">
                            <Form.Text className="text-danger">{alert && alert.message}</Form.Text>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowAddSubject(false)} title='Close' disabled={submitting}><XLg size={30} /></Button>
                    <Button variant="outline-success" onClick={() => addUpdateSubject(newSubject)} title='Save' disabled={submitting}><Floppy size={30} /></Button>
                </Modal.Footer>
            </Modal>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>
            }
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(book, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}