import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { ArrowRepeat, PlusLg } from 'react-bootstrap-icons';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import Catalogue from '../../services/catalogue';
import Book from '../../services/book';
import CellLinkRenderer from '../CellLinkRenderer';
import FormSelect from '../FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import Utils from '../../utils';

export default () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reload, setReload] = useState(true);
    const [list, setList] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookId, setBookId] = useState(searchParams.get('book') || '');
    const [selectedCatalogues, setSelectedCatalogues] = useState([]);
    const [searchText, setSearchText] = useState(searchParams.get('q') || '');
   
    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);
    const [booksLoading, setBooksLoading] = useState(true);

    useEffect(() => {
        setLoading(listLoading || booksLoading)
    }, [listLoading, booksLoading]);
    
    const [colDefs, setColDefs] = useState([
        {
            field: 'isSelected',
            headerName: '',
            editable: true,
            maxWidth: 50,
            resizable: false,
            lockPosition: 'left'
        },
        {
            field: 'code',
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/catalogues/${params.data.id}`, title: params.data.code } }
        },
        { 
            field: 'name',
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/catalogues/${params.data.id}`, title: params.data.name } }
        },
        { field: 'date', valueGetter: p => p.data.date ? moment(p.data.date).format('DD-MM-YYYY') : ''},
        {
            field: 'Books',
            valueGetter: p => p.data.Books.length,
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/books?catalogue=${params.data.id}`, title: params.data.Books.length } },
         },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator: Utils.defaultComparator,
        };
    }, []);
    const mounted = useRef(true);

    useEffect(() => { document.title = "Catalogues"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Catalogue.get(searchText, bookId)
            .then(items => {
                const selectedList = items.map(b => { return { isSelected: selectedCatalogues.map(s => s.id).includes(b.id), ...b } });

                if (mounted.current) {
                    setList(selectedList)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        Book.get('', '', '', '', '').then(items => { setBooks([{ id: '', name: '---ANY---' }, ...items]); setBooksLoading(false); });
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onNewClicked = () => {
        window.location.href = '/catalogues/0';
    }

    const onCellValueChanged = (params) => {
        var changedData = [params.data];

        const catalogueId = params.node.data.id;
        const isSelected = params.node.data.isSelected;
        const index = selectedCatalogues.map(s => s.id).indexOf(catalogueId);
        const exists = index > -1;

        if (isSelected && !exists) {
            setSelectedCatalogues([...selectedCatalogues, params.node.data]);
        }

        if (!isSelected && exists) {
            setSelectedCatalogues(selectedCatalogues.filter(b => b.catalogueId !== catalogueId));
        }

        params.api.applyTransaction({ update: changedData });
    }

    // const removeCatalogue = (id) => {
    //     setSelectedCatalogues(selectedCatalogues.filter(b => b['Catlogue Code'] !== id));
    //     const newList = list.map(b => { return { isSelected: selectedCatalogues.map(s => s['Catlogue Code']).includes(b['Catlogue Code']), ...b } });
    //     setList(newList);
    //     setReload(true);
    // }

    const onSearchBookIdChanged = (event) => {
        setBookId(event.target.value);
        setReload(true);
    }

    return (
        <LoadingPage loading={loading}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{ fontSize: '1.2rem' }}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search catalogue" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new'><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
                <Row className='mt-3'>
                    <Col sm>
                        <FormSelect value={bookId} indexfield='id' field='name' label='Book' options={books} onChange={(e) => onSearchBookIdChanged(e)} />
                    </Col>
                </Row>
            </SearchBar>
            <Content className="ag-theme-quartz p-0" top="12.7rem" overflowy='none'>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    // domLayout="autoHeight"
                    suppressScrollOnNewData='true'
                    onCellValueChanged={onCellValueChanged}
                    rowData={list}
                />
            </Content>
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedCatalogues.map(b => b['Catlogue Code']).join(','))}>Copy selected</Button> */}
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedCatalogues.map(b => b['Catlogue Code']).join(','))}>Add to Catalogue</Button> */}
            {/* {selectedCatalogues && `${selectedCatalogues.map(b => b['Catlogue Code']).join(',')}`} */}
            {/* {selected && <small>{selected[idField]}-{selected[nameField]}</small>} */}

            {/* {selectedCatalogues && selectedCatalogues.length > 0 &&
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{selectedCatalogues && `${selectedCatalogues.length} catalogues selected`}</Accordion.Header>
                        <Accordion.Body>
                            {selectedCatalogues.map(b =>
                            <ButtonGroup key={b['Catlogue Code']} style={{ margin: '0.5rem' }}>
                                <Button variant='outline-dark'>{b['Title']}</Button>
                                <Button variant='danger' onClick={() => removeCatalogue(b['Catlogue Code'])}>X</Button>
                            </ButtonGroup>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            } */}
        </LoadingPage>
    );
}