import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export default ({ loading, type, onClick, variant, disabled, title, children }) => {
    return (
        <>
            <div style={{paddingRight: '1.5rem'}}><Spinner hidden={!loading} animation="border" role="status" aria-hidden="true" /></div>
            <Button type={type || 'button'} title={title || ''} hidden={loading} variant={variant || 'primary'} onClick={onClick} disabled={disabled || loading}>
                {children}
            </Button>
        </>
    );
}