import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    fontSize: 9,
  },
  title: {
    width: "85%",
    textAlign: "left",
    fontWeight: "bold"
  },
  price: {
    width: "15%",
    textAlign: "right",
  },
  author: {
    width: "100%",
    textAlign: "left",
    fontStyle: "italic"
  },
  synopsis: {
    width: "100%",
    textAlign: "justify",
    paddingBottom: 3
  },
  pageDetails: {
    width: "10%",
    textAlign: "left",
  },
  yearDetails: {
    width: "15%",
    textAlign: "left",
  },
  placeDetails: {
    width: "15%",
    textAlign: "left",
  },
  coverDetails: {
    width: "25%",
    textAlign: "left",
  },
  volumeDetails: {
    width: "15%",
    textAlign: "left",
  },
  bookSize: {
    width: "20%",
    textAlign: "right",
  },
});

const replaceText = (text) => {
  return text.replace(/1\/4/g, "¼").replace(/1\/2/g, "½").replace(/3\/4/g, "¾");
};

export default ({ catalogue, book }) => {
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.title}>{book.name}</Text>
        <Text style={styles.price}>{catalogue.currency || book.currency} {book.CatalogueBook.price}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.author}>{book.Author.name}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.synopsis}>{book.synopsis}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.pageDetails}>{book.numberOfPages && `Pg ${book.numberOfPages}`}</Text>
        <Text style={styles.yearDetails}>{book.yearOfPublication && `Year ${book.yearOfPublication}`}</Text>
        <Text style={styles.placeDetails}>{book.placeOfPrinting}</Text>
        <Text style={styles.volumeDetails}>{book.volumeDetails && `Vol ${book.volumeDetails}`}</Text>
        <Text style={styles.coverDetails}>{book.coverDetails && `Cover ${book.coverDetails}`}</Text>
        <Text style={styles.bookSize}>{book.bookSize && `Size ${replaceText(book.bookSize)}`}</Text>
      </View>
    </>
  );
}