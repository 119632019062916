import React from "react";
import "./index.css"

export default (params) => {
  return (
    <>
      {params.title &&
        <div className="CellLinkRenderer">
          <a href={params.href} target="_self">
            {params.title}
          </a>
        </div>
      }
    </>
  )
}