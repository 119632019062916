import React from 'react';

const AuthGuard = ({ children }) => {

    const hasJWT = () => { return localStorage.getItem("token") }

    if (hasJWT()) {
        return (
            <>
                {children}
            </>
        );
    }
    
    window.location.href = "/login";
};

export default AuthGuard;