import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { ArrowRepeat, PlusLg, BoxArrowInDownRight } from 'react-bootstrap-icons';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import Party from '../../services/party';
import Catalogue from '../../services/catalogue';
import CellLinkRenderer from '../CellLinkRenderer';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import Utils from '../../utils';

export default function PageSubjects() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [catalogueId, setCatalogueId] = useState(searchParams.get('catalogue') || '');
    const [catalogue, setCatalogue] = useState({});
    const [selectedParties, setSelectedParties] = useState([]);
    const [searchText, setSearchText] = useState(searchParams.get('q') || '');

    const [submitting, setSubmitting] = useState(false);
    
    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);
    const [catalogueLoading, setCatalogueLoading] = useState(catalogueId ? true : false);

    useEffect(() => {
        setLoading(listLoading || catalogueLoading)
    }, [listLoading, catalogueLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const [colDefs, setColDefs] = useState([
        {
            field: 'isSelected',
            headerName: '',
            editable: true,
            maxWidth: 50,
            resizable: false,
            lockPosition: 'left'
        },
        { field: 'namePrefix', headerName: 'Title', maxWidth: 100 },
        {
            field: 'name',
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/parties/${params.data.id}`, title: params.data.name } }
        },
        { field: 'contactPerson' },
        { field: 'Address', flex: 5, valueGetter: p => `${p.data.addressLine1 || ''} ${p.data.addressLine2 || ''} ${p.data.addressLine3 || ''} ${p.data.addressLine4 || ''}` },
        { field: 'telephone' },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator: Utils.defaultComparator,
        };
    }, []);
    const mounted = useRef(true);

    useEffect(() => { document.title = "Parties"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Party.get(searchText)
            .then(items => {
                const selectedList = items.map(b => { return { isSelected: selectedParties.map(s => s.id).includes(b.id), ...b } });

                if (mounted.current) {
                    setList(selectedList)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (catalogueId) {
            Catalogue.getById(catalogueId).then(c => {
                setCatalogue(c);
                setSelectedParties(c.Parties);
                setReload(true);
                setCatalogueLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onNewClicked = () => {
        window.location.href = '/parties/0';
    }

    const onCellValueChanged = (params) => {
        var changedData = [params.data];

        const id = params.node.data.id;
        const isSelected = params.node.data['isSelected'];
        const index = selectedParties.map(s => s.id).indexOf(id);
        const exists = index > -1;

        if (isSelected && !exists) {
            setSelectedParties([...selectedParties, params.node.data]);
        }

        if (!isSelected && exists) {
            setSelectedParties(selectedParties.filter(b => b.id !== id));
        }

        params.api.applyTransaction({ update: changedData });
    }

    // const removeParty = (id) => {
    //     setSelectedParties(selectedParties.filter(b => b.id !== id));
    //     const newList = list.map(b => { return { isSelected: selectedParties.map(s => s.id).includes(b.id), ...b } });
    //     setList(newList);
    //     setReload(true);
    // }

    const addToCatalogue = () => {
        setSubmitting(true);
        const catalogueParties = [ ...catalogue.Parties ].filter(p => selectedParties.map(s => s.id).includes(p.id));

        selectedParties.forEach(p => {
            if (catalogueParties.findIndex(cp => cp.id === p.id) === -1) {
                catalogueParties.push(p);
            }
        });

        const trimCatalogue = { ...catalogue };
        trimCatalogue.Books = [...catalogue.Books].map(b => { return { ['Book Code']: b['Book Code'], ['Catlogue Price']: b['Catlogue Price'] } });
        trimCatalogue.Parties = [...catalogueParties].map(p => { return { id: p.id } });

        Catalogue.addUpdate(trimCatalogue).then(() => {
            window.location.href = `/catalogues/edit?id=${catalogueId}`;
        }).catch(error => {
            setAlert({ message: `Unable to add to catalogue`, isError: true });
        });
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{ fontSize: '1.2rem' }}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search subject" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new' disabled={submitting}><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload} disabled={submitting}><ArrowRepeat size={30} /></FormButton></Col>
                    {catalogueId && <Col sm="auto"><Button variant="outline-primary" onClick={() => addToCatalogue()} title='Add to catalogue' disabled={submitting}><Badge bg="primary">{selectedParties.length}</Badge> <BoxArrowInDownRight size={30} /></Button></Col>}
                </Row>
            </SearchBar>
            <Content className="ag-theme-quartz p-0" top='7.2rem' overflowy='none'>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    // domLayout="autoHeight"
                    suppressScrollOnNewData='true'
                    onCellValueChanged={onCellValueChanged}
                    rowData={list}
                />
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedParties.map(b => b.id).join(','))}>Copy selected</Button> */}
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedParties.map(b => b.id).join(','))}>Add to Catalogue</Button> */}
            {/* {selectedParties && `${selectedParties.map(b => b.id).join(',')}`} */}
            {/* {selected && <small>{selected[idField]}-{selected[nameField]}</small>} */}

            {/* {selectedParties && selectedParties.length > 0 &&
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{selectedParties && `${selectedParties.length} parties selected`}</Accordion.Header>
                        <Accordion.Body>
                            {selectedParties.map(b =>
                            <ButtonGroup key={b.id} style={{ margin: '0.5rem' }}>
                                <Button variant='outline-dark'>{b['Title']}</Button>
                                <Button variant='danger' onClick={() => removeParty(b.id)}>X</Button>
                            </ButtonGroup>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            } */}
        </LoadingPage>
    );
}