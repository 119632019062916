import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Author from '../../services/author';
import { ArrowRepeat, PlusLg, Floppy, Trash, XLg, Book } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';

export default function PageAuthors() {
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});
    const [searchText, setSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const mounted = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);

    useEffect(() => {
        setLoading(listLoading)
    }, [listLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Authors"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);
    
    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Author.get(searchText)
            .then(items => {
                if (mounted.current) {
                    setList(items)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (showModal) {
            setAlert('');
        }
    }, [showModal]);

    useEffect(() => {
        setSelected({});
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
          }, 1000);
      
          return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onNewClicked = () => {
        setSelected({});
        setShowModal(true);
    }

    const onAuthorSelected = (value) => {
        setSelected(value);
        setShowModal(true);
    }

    const addUpdateAuthor = (author) => {
        setSubmitting(true);
        Author.addUpdate(author)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setSelected({});
                    setShowModal(false);
                    setAlert({ message: `Saved ${item.name}`, isError: false });
                    setReload(true);
                }
            })
    }

    const deleteAuthor = (author) => {
        setSubmitting(true);
        Author.delete(author)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setSelected({});
                    setShowModal(false);
                    setAlert({ message: `Deleted ${author.name}`, isError: false });
                    setReload(true);
                }
            })
    }

    const openBooks = (id) => {
        window.location.href = `/books?author=${id}`;
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{fontSize:'1.2rem'}}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search author" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new'><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content textalign="center">
                {list.map(item =>
                    <Button variant={item.id === selected.id ? "primary" : "outline-dark"} className='m-2' key={item.id} onClick={() => onAuthorSelected(item)}>
                        {item.name} <Badge bg="secondary">{item.Books.length}</Badge>
                    </Button>
                )}
            </Content>

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header style={{backgroundColor:'#3498db', color:'white'}}>
                    <Modal.Title>Author</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control placeholder="New author name" aria-label="New author name" value={selected.name || ''} onChange={(e) => setSelected({ ...selected, name: e.target.value })} />
                    <Row className='justify-content-md-center'>
                        <Col md="auto">
                            <Form.Text className="text-danger">{alert && alert.message}</Form.Text>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} title='Close' disabled={submitting}><XLg size={30} /></Button>
                    {selected.id && <Button variant="outline-primary" onClick={() => openBooks(selected.id)} title='Books' disabled={submitting}><Book size={30} /></Button>}
                    {selected.id && selected.Books.length === 0 && <Button variant="outline-danger" onClick={() => deleteAuthor(selected)} title='Delete' disabled={submitting}><Trash size={30} /></Button>}
                    <Button variant="outline-success" onClick={() => addUpdateAuthor(selected)} title='Save' disabled={submitting}><Floppy size={30} /></Button>
                </Modal.Footer>
            </Modal>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    );
}