import React, { useEffect } from 'react';
import { Document, PDFViewer } from "@react-pdf/renderer";
import Content from '../Content';

export default (params) => {
  
  useEffect(() => { document.title = 'Pdf'; }, []);

  return (
    <Content top='3.5rem' padding='0rem' overflowy='hidden'>
      <PDFViewer style={{ width: '100%', height: '100%' }} onLoad={params.onLoad}>
        <Document {...params}>
          {params.children}
        </Document>
      </PDFViewer>
    </Content>
  );
}