import React from "react";
import { PlusLg } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

export default (params) => {
  return (
    <div>
      <Button variant="success" size="sm" onClick={params.onClick} title="Add"><PlusLg /></Button>
    </div>
  )
}