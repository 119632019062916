import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { ArrowRepeat, PlusLg, BoxArrowInDownRight, FileText } from 'react-bootstrap-icons';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import Book from '../../services/book';
import Subject from '../../services/subject';
import Author from '../../services/author';
import Catalogue from '../../services/catalogue';
import CellLinkRenderer from '../CellLinkRenderer';
import FormSelect from '../FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import './index.css';
import Utils from '../../utils';

export default () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reload, setReload] = useState(false);
    const [reloadCatalogue, setReloadCatalogue] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [authorId, setAuthorId] = useState(searchParams.get('author') || '');
    const [subjectId, setSubjectId] = useState(searchParams.get('subject') || '');
    const [partyId, setPartyId] = useState(searchParams.get('party') || '');
    const [catalogueId, setCatalogueId] = useState(searchParams.get('catalogue') || '');
    const [catalogue, setCatalogue] = useState({});
    const [selectedBooks, setSelectedBooks] = useState([]);
    const [searchText, setSearchText] = useState(searchParams.get('q') || '');
    const [searchColumns, setSearchColumns] = useState(searchParams.get('c') || 'any');

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);
    const [authorsLoading, setAuthorsLoading] = useState(true);
    const [subjectsLoading, setSubjectsLoading] = useState(true);
    const [catalogueLoading, setCatalogueLoading] = useState(catalogueId ? true : false);

    useEffect(() => {
        setLoading(listLoading || authorsLoading || subjectsLoading || catalogueLoading)
    }, [listLoading, authorsLoading, subjectsLoading, catalogueLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const [colDefs, setColDefs] = useState([
        {
            field: 'isSelected',
            headerName: '',
            editable: true,
            maxWidth: 50,
            resizable: false,
            lockPosition: 'left'
        },
        {
            field: 'name',
            flex: 2,
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/books/${params.data.id}?catalogue=${catalogueId}`, title: params.data.name, sold: params } }
        },
        { headerName: 'Subject', valueGetter: p => p.data.Subject ? (p.data.Subject.name ?? '') : '' },
        { headerName: 'Author', valueGetter: p => p.data.Author ? (p.data.Author.name ?? '') : '' },
        { field: 'language', valueFormatter: params => params.value?.toUpperCase() },
        {
            field: 'Catalogues',
            valueGetter: p => p.data.Catalogues.length,
            cellRenderer: CellLinkRenderer,
            cellRendererParams: (params) => { return { href: `/catalogues?book=${params.data.id}`, title: params.data.Catalogues.length } },
         },
        { field: 'sold', hide: true },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator: Utils.defaultComparator
        };
    }, []);
    const mounted = useRef(true);

    useEffect(() => { document.title = "Books"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReloadCatalogue(true))
        return () => { window.removeEventListener("focus", () => setReloadCatalogue(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Book.get(searchColumns, searchText, authorId, subjectId, partyId)
            .then(items => {
                const selectedList = items.map(b => { return { isSelected: selectedBooks.map(s => s.id).includes(b.id), ...b } });
                if (mounted.current) {
                    setList(selectedList)
                    setReload(false);
                    setListLoading(false);
                }
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        mounted.current = true;
        if(!reloadCatalogue) {
            return;
        }
        
        if (catalogueId) {
            Catalogue.getById(catalogueId).then(c => {
                if (mounted.current) {
                    setCatalogue(c);
                    setSelectedBooks(c.Books);
                    setCatalogueLoading(false);
                }
            });
        }
        
        setReload(true);
        setReloadCatalogue(false);
        return () => mounted.current = false;
    }, [reloadCatalogue])

    useEffect(() => {
        Author.get('').then(items => { setAuthors([{ id: '', name: '---ANY---' }, { id: 0, name: '---blank---' }, ...items]); setAuthorsLoading(false); });
        Subject.get('').then(items => { setSubjects([{ id: '', name: '---ANY---' }, { id: 0, name: '---blank---' }, ...items]); setSubjectsLoading(false); });
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onNewClicked = () => {
        window.location.href = '/books/0';
    }

    const onCellValueChanged = (params) => {
        var changedData = [params.data];

        const id = params.node.data.id;
        const isSelected = params.node.data.isSelected;
        const index = selectedBooks.map(s => s.id).indexOf(id);
        const exists = index > -1;

        if (isSelected && !exists) {
            setSelectedBooks([...selectedBooks, params.node.data]);
        }

        if (!isSelected && exists) {
            setSelectedBooks(selectedBooks.filter(b => b.id !== id));
        }

        params.api.applyTransaction({ update: changedData });
    }

    // const removeBook = (id) => {
    //     setSelectedBooks(selectedBooks.filter(b => b.id !== id));
    //     const newList = list.map(b => { return { isSelected: selectedBooks.map(s => s.id).includes(b.id), ...b } });
    //     setList(newList);
    //     setReload(true);
    // }

    const onSearchAuthorIdChanged = (event) => {
        setAuthorId(event.target.value);
        setReload(true);
    }

    const onSearchSubjectIdChanged = (event) => {
        setSubjectId(event.target.value);
        setReload(true);
    }

    const addToCatalogue = () => {
        setSubmitting(true);
        const catalogueBooks = [...catalogue.Books].filter(b => selectedBooks.map(s => s.id).includes(b.id));

        selectedBooks.forEach(b => {
            if (catalogueBooks.findIndex(cb => cb.id === b.id) === -1) {
                catalogueBooks.push({ ...b, CatalogueBook: { price: b.priceInCurrency } });
            }
        });

        const trimCatalogue = { ...catalogue };
        trimCatalogue.Books = [...catalogueBooks].map(b => { return { id: b.id, price: b.CatalogueBook.price } });

        Catalogue.addUpdate(trimCatalogue).then(() => {
            window.location.href = `/catalogues/${catalogueId}`;
        }).catch(error => {
            setAlert({ message: `Unable to add to catalogue`, isError: true });
        });
    }

    const onChangeSearchColumns = (column) => {
        if (column === 'any') {
            setSearchColumns('any');
            setReload(true);
            return;
        }

        let newColumns = searchColumns.includes(column) ? searchColumns.split(',').filter(col => col !== column) : [...searchColumns.split(','), column];
        newColumns = newColumns.filter(col => col !== 'any');
        newColumns = newColumns.filter(col => col !== '');
        setSearchColumns(newColumns.join(','));        
        setReload(true);
    }

    const onInsertsCreate = () => {
        window.open(`/inserts/preview/${selectedBooks.map(b => b.id).join(',')}`, '_target');
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{ fontSize: '1.2rem' }}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search subject" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new' disabled={submitting}><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload} disabled={submitting}><ArrowRepeat size={30} /></FormButton></Col>
                    {catalogueId && <Col sm="auto"><Button variant="outline-primary" onClick={() => addToCatalogue()} title='Add to catalogue' disabled={submitting}><Badge bg="primary">{selectedBooks.length}</Badge> <BoxArrowInDownRight size={30} /></Button></Col>}
                    {!catalogueId && selectedBooks.length > 0 && <Col sm="auto"><Button variant="outline-primary" onClick={() => onInsertsCreate()} title='Generate Inserts' disabled={submitting}><Badge bg="primary">{selectedBooks.length}</Badge> <FileText size={30} /></Button></Col>}
                </Row>
                <Row className='mt-2' style={{alignItems:'center'}}>
                    <Col sm="auto"><Badge bg="danger" style={{ fontSize: '1.2rem', visibility: 'hidden' }}>{list.length}</Badge></Col>
                    <Col sm="auto">
                        Search in:
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Any" checked={searchColumns.includes('any')} onChange={(e) => onChangeSearchColumns('any')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Name" checked={searchColumns.includes('name')} onChange={(e) => onChangeSearchColumns('name')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Subject" checked={searchColumns.includes('subject')} onChange={(e) => onChangeSearchColumns('subject')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Author" checked={searchColumns.includes('author')} onChange={(e) => onChangeSearchColumns('author')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Synopsis" checked={searchColumns.includes('synopsis')} onChange={(e) => onChangeSearchColumns('synopsis')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Remark" checked={searchColumns.includes('remark')} onChange={(e) => onChangeSearchColumns('remark')} />
                    </Col>
                    <Col sm="auto">
                        <Form.Check type="checkbox" label="Year" checked={searchColumns.includes('yearOfPublication')} onChange={(e) => onChangeSearchColumns('yearOfPublication')} />
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col sm>
                        <FormSelect value={authorId} indexfield='id' label='Author' field='name' options={authors} onChange={(e) => onSearchAuthorIdChanged(e)} />
                    </Col>
                    <Col sm>
                        <FormSelect value={subjectId} indexfield='id' label='Subject' field='name' options={subjects} onChange={(e) => onSearchSubjectIdChanged(e)} />
                    </Col>
                </Row>
            </SearchBar>
            <Content className="ag-theme-quartz p-0" top="14.8rem" overflowy='none'>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    // domLayout="autoHeight"
                    suppressScrollOnNewData='true'
                    onCellValueChanged={onCellValueChanged}
                    rowData={list}
                    rowClassRules= {
                        {
                            "row-sold": params => params.api.getValue("sold", params.node),
                            "row-unsold": params => !params.api.getValue("sold", params.node)
                        }
                      }
                />
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedBooks.map(b => b.id).join(','))}>Copy selected</Button> */}
            {/* <Button onClick={() => navigator.clipboard.writeText(selectedBooks.map(b => b.id).join(','))}>Add to Catalogue</Button> */}
            {/* {selectedBooks && `${selectedBooks.map(b => b.id).join(',')}`} */}
            {/* {selected && <small>{selected[idField]}-{selected[nameField]}</small>} */}

            {/* {selectedBooks && selectedBooks.length > 0 &&
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{selectedBooks && `${selectedBooks.length} books selected`}</Accordion.Header>
                        <Accordion.Body>
                            {selectedBooks.map(b =>
                            <ButtonGroup key={b.id} style={{ margin: '0.5rem' }}>
                                <Button variant='outline-dark'>{b.name}</Button>
                                <Button variant='danger' onClick={() => removeBook(b.id)}>X</Button>
                            </ButtonGroup>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            } */}
        </LoadingPage>
    );
}