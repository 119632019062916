import { Text, View, StyleSheet } from "@react-pdf/renderer";
import PdfCatalogueBook from "./PdfCatalogueBook";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "top",
    fontSize: 9,
  },
  index: {
    width: "5%",
    textAlign: "right",
    paddingRight: 8,
  },
  book: {
    width: "95%",
    textAlign: "left",
  },
});

export default ({ catalogue }) => {
  return (
    <>
      {catalogue.Books?.map((book, index) => (
        <View key={index}>
          <View style={styles.row}><Text> </Text></View>
          <View style={styles.row}>
            <Text style={styles.index}>{index + 1}</Text>
            <View style={styles.book}>
              <PdfCatalogueBook catalogue={catalogue} book={book} />
            </View>
          </View>
          <View style={styles.row}><Text> </Text></View>
        </View>
      ))}
    </>
  );
}