class Catalogue {
  async get(filter, book) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/catalogues?q=${filter}&book=${book}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/catalogues/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(catalogue) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/catalogues/${catalogue.id || ''}`, {
      method: catalogue.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(catalogue)
    });

    return await data.json();
  }

  async duplicate(catalogue) {
    const newCalaogue = {
      ...catalogue,
      id: null,
      code: `${catalogue.code}-copy`,
    };
    return await this.addUpdate(newCalaogue);
  }

  async delete(catalogue) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/catalogues/${catalogue.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Catalogue();