class Book {
  async get(columns, filter, author, subject, party) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/books?c=${columns}&q=${filter}&author=${author}&subject=${subject}&party=${party}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return [];
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/books/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getByIds(ids) {
    const bookIds = ids.split(',');
    const books = await Promise.all(bookIds.map(id => this.get('id', id, '', '', '')));
    return books.map(b => b[0]);
  }

  async addUpdate(book) {
    if (book.id === '0') {
      book.id = null;
    }

    const data = await fetch(`${process.env.REACT_APP_API_URL}/books/${book.id || ''}`, {
      method: book.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(book)
    });
    return await data.json();
  }

  async delete(book) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/books/${book.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Book();